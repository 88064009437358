<template>
    <div class="review_main">
        <div class="review_table_right" v-for="bottoncolor in reviewtabledata" :key="bottoncolor">
            <span> {{ rrname }} </span>
            <div class="review_table_sp1">
                <el-button
                    :type="(bottoncolor.listing评审 == '未评审' ? 'info' : (bottoncolor.listing评审 == '评审通过' ? 'success' : (bottoncolor.listing评审 == '评审中' ? 'primary' : (bottoncolor.listing评审 == '延期' ? 'warning' :  (bottoncolor.listing评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select3()">listing评审</el-button>
            </div>
            <div class="review_table_bottom">
                
            </div>
            <div class="review_table_sp2">
                <el-button
                    :type="(bottoncolor.推广评审 == '未评审' ? 'info' : (bottoncolor.推广评审 == '评审通过' ? 'success' : (bottoncolor.推广评审 == '评审中' ? 'primary' : (bottoncolor.推广评审 == '延期' ? 'warning' :  (bottoncolor.推广评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select2()">
                    <div>推广评审
                        
                    </div>
                </el-button>
            </div>
            <el-button plain type="primary" @click="add_auto_project()">添加维护动作</el-button>
            <el-button class="review_botton3" @click="jiexiang()" v-if="bottoncolor.项目结项评审 == '未评审'"
            :type="(bottoncolor.项目结项评审 == '未评审' ? 'info' : (bottoncolor.项目结项评审 == '评审通过' ? 'success' : 'danger'))">
                        未结项
                </el-button>
                <el-button class="review_botton3" v-else-if="bottoncolor.项目结项评审 == '评审通过'"
            :type="(bottoncolor.项目结项评审 == '未评审' ? 'info' : (bottoncolor.项目结项评审 == '评审通过' ? 'success' : 'danger'))">
                        已结项
                </el-button>
        </div> 
        <div class="review_table_right2">
            <el-table :data="gridData1" style="width: 100%" height="100%">
                        <el-table-column prop="项目负责人" label="负责人" width="65">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.项目负责人 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="评审点" label="评审点" width="90">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.评审点 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="CR结论" label="CR结论" width="175">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.CR结论 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="产品定位结论" label="产品定位结论" width="245">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.产品定位结论 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="下一步动作" label="下一步动作" width="175">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.下一步动作 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="评审开启时间" label="评审开启时间" width="105">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.评审开启时间 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="截止时间" label="截止时间" width="105" fixed="right">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.截止时间 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="评审通过时间" label="评审通过时间" width="105">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.评审通过时间 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="评审状态" label="评审状态" width="80" fixed="right">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    {{ scope.row.评审状态 }}
                                </div>
                            </template>
                        </el-table-column>
                       
                    </el-table> 
        </div>
        <!-- ---------------------------------------------------------------------------------------------- -->
        <el-dialog :title="title" :visible="dialogVisible1" width="750px" :before-close="handleClose1">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' :  (form.评审状态 == '延期审核中' ? 'warning' : 'danger')))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容" >
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData">
                            <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                            <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                            <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                            <!-- <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column> -->
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="CR结论" prop="CR结论">
                    <el-input type="textarea" :rows="3" v-model="form.CR结论" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData" max-height="500px">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="mini" plain type="danger" slot="reference" style="float: right;"
                                        @click="delect_load(scope.row)">删除
                                    </el-button>
                                    <el-button size="mini" plain type="primary" slot="reference"
                                        style="float: right;margin-right: 5px;" @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="产品定位结论" prop="产品定位结论">
                    <el-input type="textarea" :rows="3" v-model="form.产品定位结论" autocomplete="off" placeholder="(项目目标)"
                        style="width: 130%;" />
                </el-form-item>
                <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData1">
                            <el-table-column width="150" property="项目负责人" label="项目负责人"></el-table-column>
                            <el-table-column width="150" property="CR结论" label="CR结论"></el-table-column>
                            <el-table-column width="200" property="产品定位结论" label="产品定位结论"></el-table-column>
                            <el-table-column width="150" property="评审通过时间" label="评审通过时间"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 18%;height: 50px;margin-top: 10px;">评审结论</el-button>
                    </el-popover>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="info" plain style="margin-left: 114%;" @click="download">下载附件</el-button>
                </el-form-item> -->
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :file-list="fileList" :on-preview="handlePreview" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save_updata_b" :loading="isSending">提交</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible2" width="750px" :before-close="handleClose2">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;" type="info">
                        未评审</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="2" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="CR结论" prop="CR结论">
                    <el-input type="textarea" :rows="3" v-model="form.CR结论" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData" max-height="500px">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="mini" plain type="danger" slot="reference" style="float: right;"
                                        @click="delect_load(scope.row)">删除
                                    </el-button>
                                    <el-button size="mini" plain type="primary" slot="reference"
                                        style="float: right;margin-right: 5px;" @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="产品定位结论" prop="产品定位结论">
                    <el-input type="textarea" :rows="3" v-model="form.产品定位结论" autocomplete="off" placeholder="(项目目标)"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="下一步动作" prop="下一步动作">
                    <el-input type="textarea" :rows="3" v-model="form.下一步动作" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目负责人" multiple placeholder="请选择项目负责人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <!-- <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <el-select v-model="form.项目主管" multiple placeholder="请选择项目主管">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        -->
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <!-- <el-form-item label="流量分析" prop="流量分析">
                    <el-select v-model="form.QA" multiple placeholder="请选择流量分析">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="截止时间" prop="截止时间">
                    <el-date-picker v-model="form.截止时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传附件" prop="上传附件">
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :on-preview="handlePreview" :file-list="fileList" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" :loading="isSending">发起评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible3" width="750px" :before-close="handleClose3">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>                                                                                                                                                                                                                                                   
                <!-- <el-form-item>
                    <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData">
                            <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                            <el-table-column width="150" property="评审结果" label="评审  结果"></el-table-column>
                            <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                    </el-popover>
                </el-form-item> -->
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="CR结论" prop="CR结论">
                    <el-input type="textarea" :rows="3" v-model="form.CR结论" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData" max-height="500px">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="mini" plain type="danger" slot="reference" style="float: right;"
                                        @click="delect_load(scope.row)">删除
                                    </el-button>
                                    <el-button size="mini" plain type="primary" slot="reference"
                                        style="float: right;margin-right: 5px;" @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="产品定位结论" prop="产品定位结论">
                    <el-input type="textarea" :rows="3" v-model="form.产品定位结论" autocomplete="off" placeholder="(项目目标)"
                        style="width: 130%;" />
                </el-form-item>
                <!-- <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData1">
                            <el-table-column width="150" property="项目负责人" label="项目负责人"></el-table-column>
                            <el-table-column width="150" property="CR结论" label="CR结论"></el-table-column>
                            <el-table-column width="200" property="产品定位结论" label="产品定位结论"></el-table-column>
                            <el-table-column width="150" property="评审通过时间" label="评审通过时间"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 18%;height: 50px;margin-top: 10px;">评审结论</el-button>
                    </el-popover> -->
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="下一步动作" prop="下一步动作">
                    <el-input type="textarea" :rows="3" v-model="form.下一步动作" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目负责人1" multiple placeholder="请选择项目负责人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <!-- <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <el-select v-model="form.项目主管1" multiple placeholder="请选择项目主管">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form> -->
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <!-- <el-form-item label="流量分析" prop="流量分析">
                    <el-select v-model="form.QA1" multiple placeholder="请选择流量分析">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="下次截止时间" prop="下次截止时间">
                    <el-date-picker v-model="form.下一次截止时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传附件" prop="上传附件">
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :on-preview="handlePreview" :file-list="fileList" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="remark"  :loading="isSending">发起下一次评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible5" width="750px" :before-close="handleClose5">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容" >
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData">
                            <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                            <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                            <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="CR结论" prop="CR结论">
                    <el-input type="textarea" :rows="3" v-model="form.CR结论" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData" max-height="500px">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="mini" plain type="danger" slot="reference" style="float: right;"
                                        @click="delect_load(scope.row)">删除
                                    </el-button>
                                    <el-button size="mini" plain type="primary" slot="reference"
                                        style="float: right;margin-right: 5px;" @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="产品定位结论" prop="产品定位结论">
                    <el-input type="textarea" :rows="3" v-model="form.产品定位结论" autocomplete="off" placeholder="(项目目标)"
                        style="width: 130%;" />
                </el-form-item>
                <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData1">
                            <el-table-column width="150" property="项目负责人" label="项目负责人"></el-table-column>
                            <el-table-column width="150" property="CR结论" label="CR结论"></el-table-column>
                            <el-table-column width="200" property="产品定位结论" label="产品定位结论"></el-table-column>
                            <el-table-column width="150" property="评审通过时间" label="评审通过时间"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 18%;height: 50px;margin-top: 10px;">评审结论</el-button>
                    </el-popover>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :file-list="fileList" :on-preview="handlePreview" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">选取文件后点击上传服务器上传文件(文件名不可含有空格和特殊符号)</div>
                    </el-upload>
                </el-form-item>
                <!-- <el-form-item label="延期时间" prop="延期时间">
                    <el-date-picker v-model="form.发起延期时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save_updata_b" :loading="isSending">提交</el-button>
                <!-- <el-button type="primary" @click="late_review_uptime" :loading="isSending">发起延期</el-button> -->
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible_a" width="30%" :before-close="handleClose_a">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" placeholder="不能带有特殊字符和空格" />
                </el-form-item>
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" :disabled="true" autocomplete="off" />
                </el-form-item>
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off"
                        placeholder="若没有备注请写上‘无备注’" />
                </el-form-item>
                <el-form-item label="执行人" prop="执行人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.执行人" placeholder="请选择执行人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维护动作" prop="维护动作">
                    <el-select v-model="form.维护动作" filterable allow-create default-first-option placeholder="请选择维护动作">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行频率" prop="执行频率">
                    <el-input-number size="small" v-model="num1"></el-input-number>
                </el-form-item>
                <el-form-item label="是否休息日执行" prop="是否休息日执行" label-width="22%">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.是否周末执行" placeholder="请选择是或否">
                        <el-option v-for="item in tip" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="固定执行时间" prop="固定执行时间">
                    <el-date-picker v-model="form.固定执行时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="save_action" :loading="isSending2">确 定</el-button>
            </span>
        </el-dialog>
    </div>
    
    
    
</template>

<script>
export default {
    data() {
        return {
            input: '',
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            dialogVisible1: false,
            dialogVisible2: false,
            dialogVisible3: false,
            dialogVisible4: false,
            dialogVisible_a: false,
            form: {},
            reviewtabledata: [],
            size: '',
            title: '',
            visible: false,
            num1: 1,
            tableData: [],
            fileList: [],
            tableDataover: [],
            isSending: false,
            tip: [{
                value: '是',
                label: '是'
            }, {
                value: '否',
                label: '否'
            }],
            psname: {
                项目可行性评审: '项目可行性评审',
                图片需求评审: '图片需求评审',
                listing评审: 'listing评审',
                广告目标及方案评审: '广告目标及方案评审',
                推广评审: '推广评审',
                两个月项目可持续性评审: '两个月项目可持续性评审',
                二次备货策略或首月销售情况评审: '二次备货策略或首月销售情况评审',
                广告第四星期效果评审: '广告第四星期效果评审',
                广告第三星期效果评审: '广告第三星期效果评审',
                广告第二星期效果评审: '广告第二星期效果评审',
                广告首星期效果评审: '广告首星期效果评审',
                项目结项评审: '项目结项评审',
            },
            options: [{
                value: '李健东',
                label: '李健东'
            }, {
                value: '温智超',
                label: '温智超'
            }, {
                value: '陈志芳',
                label: '陈志芳'
            }, {
                value: '李兆轩',
                label: '李兆轩'
            }, {
                value: '张梅丽',
                label: '张梅丽'
            }, {
                value: '罗航',
                label: '罗航'
            }, {
                value: '李秀娟',
                label: '李秀娟'
            }, {
                value: '张莉敏',
                label: '张莉敏'
            }, {
                value: '卢凤颖',
                label: '卢凤颖'
            }, {
                value: '林宝玲',
                label: '林宝玲'
            }, {
                value: '周才德',
                label: '周才德'
            }, {
                value: '郭诗艺',
                label: '郭诗艺'
            }],
            options1: [{
                value: '前台是否异常',
                label: '前台是否异常'
            }, {
                value: '备货发货',
                label: '备货发货'
            }, {
                value: '清库',
                label: '清库'
            }, {
                value: '移入移出',
                label: '移入移出'
            }],
            rules: {
                项目名称: [{ required: true, message: '输入项目名称以新建任务', trigger: 'blur' }],
                项目内容: [{ required: true, message: '添加项目内容', trigger: 'blur' }],
                执行人: [{ required: true, message: '请选择执行人', trigger: 'blur' }],
                维护动作: [{ required: true, message: '请选择维护动作', trigger: 'blur' }],
                项目负责人: [{ required: true, message: '请选择项目负责人', trigger: 'blur' }],
            },
            value: '',
            value1: [],
            value2: [],
            value3: [],
            formLabelWidth: '80px',
            timer: null,
            rrname: '',
            reviewer_dataname: '',
            gridData: [],
            gridData1: [],
            fileData: [],
            datason: []
        }
    },
    // created(){
    //     const that = this
    //     that.rrname = this.$route.query.reviewname
    //     // this.init(this.rrname)
    // },
    mounted() {
        const that = this
        that.rrname = this.$route.query.reviewname
        this.init()
        // this.init(that.rrname)
        // 接受reviewname的参数，并将他赋给rrname
        // 像后端发送rrname的参数，让后端向数据库执行只接受有关rrname的全部字段并将他返回给前端
        // router.replace({ path: '/dashboard/review/review_table'})
    },
    methods: {
        init() {
            let bbname = this.rrname
            console.log(bbname)
            this.$axios.get(`/vue/review/review_table_all?bbname=${bbname}`).then(res => {
                console.log(res);
                this.reviewtabledata = res.data
                this.gridData1 = res.data1
            })
                .console(error => {
                    console.log(error)
                })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        select2() {
            let pszt = this.reviewtabledata[0].推广评审
            if (pszt == '未评审') {
                this.dialogVisible2 = true
                this.title = this.psname.推广评审
                this.form = {}
                this.form.项目名称 = this.reviewtabledata[0].项目名称
                this.form.项目内容 = this.reviewtabledata[0].项目内容
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.form.评审点 = this.title
            } else if (pszt == '评审中' || pszt == '延期审核中' || pszt == '超期') {
                this.dialogVisible3 = true
                this.title = this.psname.推广评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '评审通过'){
                this.dialogVisible3 = true
                this.title = this.psname.推广评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else{
                this.dialogVisible5 = true
                this.title = this.psname.推广评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } 
        },
        select3() {
            let pszt = this.reviewtabledata[0].listing评审
            if (pszt == '未评审') {
                this.dialogVisible2 = true
                this.title = this.psname.listing评审
                this.form = {}
                this.form.项目名称 = this.reviewtabledata[0].项目名称
                this.form.项目内容 = this.reviewtabledata[0].项目内容
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.form.评审点 = this.title
            } else if (pszt == '评审中' || pszt == '延期审核中' || pszt == '超期') {
                this.dialogVisible3 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '评审通过'){
                this.dialogVisible3 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else{
                this.dialogVisible5 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        add_auto_project(){
            this.title = '添加维护动作'
            this.form = {}
            this.form.项目名称 = this.reviewtabledata[0].项目名称
            this.form.项目内容 = this.reviewtabledata[0].项目内容
            this.dialogVisible_a = true
        },
        jiexiang(){
            this.$confirm('此操作将该项目标记为结项, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({
					type: 'success',
					message: '结项成功!'
				})
				this.$axios.post('/vue/review/review_jiexiang', { name: this.rrname }).then(res => {
					console.log(res)
					this.init()
				})
					.console(error => {
						console.log(error)
						alert('发生错误:==' + error)
					})
			})
        },
        handEdit(index, row) {
            this.dialogVisible = true
            this.title = '编辑记录'
            this.form = JSON.parse(JSON.stringify(row))
        },
        reviewer_data(a, b) {
            this.$axios.post(`/vue/review/reviewer_data`, { title: a, rename: b }).then(res => {
                console.log(res)
                this.gridData = res.data
                this.fileData = res.data2
            }).console(error => {
                console.log(error)
            })
        },
        review_data_son(a, b) {
            this.$axios.post(`/vue/review/review_data_son`, { title: a, rename: b }).then(res => {
                console.log(res)
                this.datason = res.data
                this.reread(a, b)
            }).catch(error => {
                console.log("错误：", error);
                if(error == "TypeError: Cannot set properties of undefined (setting '评审点')"){
                    alert('发生错误:=='+error)
                    location.reload()
                }
            });
        },
        reread(a, b) {
            this.form = this.datason[0]
            this.form.评审点 = this.title
            this.reviewer_data(a, b)
        },
        handleClose1() {
            this.dialogVisible1 = false
            this.init()
        },
        handleClose2() {
            this.dialogVisible2 = false
            this.init()
        },
        handleClose3() {
            this.dialogVisible3 = false
            this.init()
        },
        handleClose4() {
            this.dialogVisible4 = false
            this.init()
        },
        handleClose5() {
            this.dialogVisible5 = false
            this.init()
        },
        handleClose_a() {
            this.dialogVisible_a = false
        },
        handlePreview(file) {
            console.log(file);
        },
        reset() {
            let id = undefined
            if ('id' in this.form) {
                id = this.form.id
            }
            this.form = {}
            if (id != undefined) this.form.id = id
        },
        save() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_subbmit', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose2() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error+ '请检查是否填写完整')
                this.isSending = false
            });
        },
        save_updata_b() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_updata_b', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error+ '请检查是否填写完整')
                this.isSending = false
            });
        },
        late_review_uptime() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/late_review_uptime', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose5() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error+ '请检查是否填写完整')
                this.isSending = false
            });
        },
        save_f() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_subbmit_f', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose4() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error + '请检查是否填写无误，广告目标以及方案评审是否已通过')
            });
        },
        remark() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_remark', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose3() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                this.isSending = false
                console.log("错误：", error);
                alert('发生错误:=='+error+'请检查是否填写完整')
            });
        },
        beforeUpload(file) {
            let fileObj = file.file
            let fd = new FormData();
            alert('上传中。。。。。。。。。。。');
            fd.append('file', fileObj);//传文件
            fd.append('title', this.title);
            fd.append('name', this.reviewer_dataname)
            this.$axios.post('/vue/review/review_upload', fd).then(res => {
                if (res.code == 200) {
                    alert('上传成功');
                } else {
                    alert('上传失败');
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error)
            });
        },
        httpRequest(param) {
            let fileObj = param.file; // 相当于input里取得的files
            let data = new FormData(); // FormData 对象
            data.append("file", fileObj); // 文件对象
            data.append("title", this.title);
            data.append("name", this.reviewer_dataname);
            this.$axios.post('/vue/review/review_upload', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                if (res.code == 200) {
                    console.log("结果：", res);
                    alert('上传成功')
                } else {
                    alert('上传失败')
                }
                this.fileList = []
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error)
            });
        },
        register(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    console.log("有效")
                    this.$refs.upload.submit();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        download(row) {
            let url = "https://kebeidianzi.com/vue/review/review_download/"+ encodeURIComponent(row.文件名) + "?name=" + encodeURIComponent(row.项目名称) + "&er=" + row.评审点
            this.$axios.get(`/vue/review/review_download/` + row.文件名, { params: { name: row.项目名称, er: row.评审点 }, responseType: 'blob' }).catch(error => {
                console.log("错误", error)
                // alert('下载发生错误:==' + error)
            })
            window.open(url, '_blank')
        },
        delect_load(row) {
            this.$axios.post(`/vue/review/delect_load`, { name: row.项目名称, filename: row.文件名, er: row.评审点 }).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
                this.review_data_son(this.title, this.reviewer_dataname)
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
        save_action() {
            this.isSending2 = true
            this.form.执行频率 = this.num1
            console.log(this.form);
            this.$axios.post('/vue/review/action_add', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose_a() }
                    });
                    this.isSending2 = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending2 = false
                }
            })
                .catch(error => {
                    console.log(error)
                    alert('发生错误:==' + '请检查内容是否填写完整')
                    this.isSending2 = false
                })
        },
    },
    rightclick() {
        this.visible = true
    },
}
</script>
  
<style>
.review_main {
    height: 100%;
    width: 100%;
}

.review_table_right {
    height: 100%;
    width: 30%;
    border: 2px solid rgb(207, 207, 207);
    float: left;
    
}
.review_table_right2 {
    height: 100%;
    margin-left: 5%;
    width: 65%;
    border: 2px solid rgb(207, 207, 207);
    float: left;
    
}

.review_table_sp1 {
    margin-top: 18%;
    margin-left: 30%;
    height: 35%;
    width: 100%;
    
}

.review_table_sp2 {
    margin-left: 30%;
    margin-top: 10%;
    height: 38.5%;
    width: 100%;

}


.review_botton {
    height: 180px;
    width: 180px;
}

.review_botton3 {
    float: right;
    
}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
.el-button--cyan.is-active,
.el-button--cyan:active {
  background: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
 
.el-button--cyan:focus,
.el-button--cyan:hover {
  background: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
 
.el-button--cyan {
  background-color: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
</style>