<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">项目评审</el-menu-item>

            </el-menu>
        </div>
        <div class="select_menu">
            <!-- <el-input v-model="input" placeholder="搜索标题和id" style="width: 10%;"></el-input> -->
            <el-input v-model="xmmc" placeholder="请输入项目名称" style="width: 200px; margin-left: 10px;"></el-input>
            <el-button style="margin-left: 10px;" @click="init" :loading="isSending">获取项目</el-button>
            <el-button style="margin-left: 10px;" @click="init_i" :loading="isSending">获取我的项目</el-button>
        </div>
        <div class="review">
            <div class="review_div1">
                <!-- <div style="width: 100%;"><span>未完成</span></div> -->
                <el-button size="medium" @click="add" style="width: 100%;">新增项目</el-button>
                <div class="review_table1">
                    <el-table :data="tableData" style="width: 100%" :show-header="false" height="100%"
                        @row-contextmenu="rightclick">
                        <el-table-column width="15">
                            <!-- <template slot-scope="scope" >
                                <div>
                                    <el-avatar size="large">
                                        <p style="font-size: 3px;">{{ scope.row.name }}</p>
                                    </el-avatar>
                                </div>
                            </template> -->
                        </el-table-column>
                        <el-table-column prop="项目名称" label="标题" width="210">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" @click="jojo(scope.row)"
                                    style="padding-top: 5px;">
                                    <!-- {{ scope.row.项目名称 }} -->
                                    <el-badge value="done" type="success" v-if="scope.row.项目结项评审 === '评审通过'">
                                        {{ scope.row.项目名称 }}
                                    </el-badge>
                                    <el-badge value="!" v-else-if="scope.row.有无评审 === '未评审' || scope.row.有无评审 === '延期审核中'" type="primary">
                                        {{ scope.row.项目名称 }}
                                    </el-badge>
                                    <el-badge value="超" type="danger" v-else-if="scope.row.有无评审 === '超期'">
                                        {{ scope.row.项目名称 }}
                                    </el-badge>
                                    <el-badge value="延" type="warning" v-else-if="scope.row.有无评审 === '延期'">
                                        {{ scope.row.项目名称 }}
                                    </el-badge>
                                    <el-badge v-else>
                                        {{ scope.row.项目名称 }}
                                    </el-badge>
                                    <!-- <el-badge is-dot class="item"> {{ scope.row.项目名称 }}</el-badge> -->
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="功能" width="10">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <el-tag style="float: right;background-color;"
                                        :type="(scope.row.lv == '较低' ? 'info' : (scope.row.lv == '普通' ? 'success' : (scope.row.lv == '紧急' ? 'danger' : (scope.row.lv == '非常紧急' ? 'warning' : 'danger'))))">
                                        {{ scope.row.lv }}
                                    </el-tag>
                                    <el-popconfirm title="确认标记已完成吗？" @confirm="Conditionupdt_y(scope.$index, scope.row)">
                                        <el-button  size="mini" icon="el-icon-minus" circle
                                            slot="reference" style="float: right;"></el-button>
                                    </el-popconfirm>
                                    <el-popconfirm title="确认删除吗？" @confirm="handDelete(scope.$index, scope.row)">
                                        <el-button  size="mini" icon="el-icon-circle-close" circle
                                            slot="reference" style="float: right;"></el-button>
                                    </el-popconfirm>
                                    
                                </div>
                            </template>
                        </el-table-column> -->
                        <!-- <el-table-column width="20" >
                        </el-table-column> -->
                    </el-table>
                </div>
                <!-- <el-button icon="el-icon-plus" size="medium" @click="add" style="width: 100%;"></el-button> -->
                <!-- <el-button size="mini" class="add" @click="add" icon="el-icon-plus"
              >添加一条记录</el-button
            > -->
            </div>

            <div class="review_div1">
                <div class="review_table2">
                    <router-view :key="$route.fullPath">

                    </router-view>
                </div>
                
            </div>
        </div>
        <el-dialog :title="title" :visible="dialogVisible" width="700px" :before-close="handleClose">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" placeholder="不能带有特殊字符和空格" />
                </el-form-item>
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" />
                </el-form-item>
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目负责人" multiple placeholder="请选择项目负责人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="项目主管" prop="项目主管">
                    <el-select v-model="form.项目主管" multiple placeholder="请选择项目主管">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="流量分析" prop="流量分析">
                    <el-select v-model="form.QA" multiple placeholder="请选择流量分析">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="save" :loading="isSending">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            input: '',
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            isSending: false,
            isSending2: false,
            xmmc:'',
            dialogVisible: false,
            form: {},
            size: '',
            title: '',
            visible: false,
            tableData: [],
            tableDataover: [],
            rules: {
                项目名称: [{ required: true, message: '输入项目名称以新建任务', trigger: 'blur' }],
                项目内容: [{ required: true, message: '添加项目内容', trigger: 'blur' }],
            },
            options: [{
                value: '李健东',
                label: '李健东'
            }, {
                value: '温智超',
                label: '温智超'
            }, {
                value: '陈志芳',
                label: '陈志芳'
            }, {
                value: '李兆轩',
                label: '李兆轩'
            }, {
                value: '张梅丽',
                label: '张梅丽'
            }, {
                value: '罗航',
                label: '罗航'
            }, {
                value: '李秀娟',
                label: '李秀娟'
            }, {
                value: '张莉敏',
                label: '张莉敏'
            }, {
                value: '卢凤颖',
                label: '卢凤颖'
            }, {
                value: '林宝玲',
                label: '林宝玲'
            }, {
                value: '周才德',
                label: '周才德'
            }, {
                value: '郭诗艺',
                label: '郭诗艺'
            }],
            value: '',
            value1: [],
            value2: [],
            value3: [],
            formLabelWidth: '80px',
            timer: null,
            sxsx: ''
        }
    },
    methods: {
        init() {
            this.isSending = true
            let params = {
				query: this.xmmc
			}
            this.sxsx = '1'
            this.$axios.get('/vue/review/review_all', { params}).then(res => {
                console.log(res);
                if (res.msg == 'success') {
					this.tableData = res.data
					this.isSending = false
				} else {
					alert(res.message)
					this.isSending = false
				}
            })
                .console(error => {
                    console.log(error)
                    this.isSending = false
                })
        },
        init_i() {
            this.isSending = true
            let params = {
				query: this.xmmc
			}
            this.sxsx = '1'
            this.$axios.get('/vue/review/review_all_i', { params}).then(res => {
                console.log(res);
                if (res.msg == 'success') {
					this.tableData = res.data
					this.isSending = false
				} else {
					alert(res.message)
					this.isSending = false
				}
            })
                .console(error => {
                    console.log(error)
                    this.isSending = false
                })
        },
        inite() {
            this.isSending2 = true
            this.sxsx = '2'
            this.$axios.get('/vue/review/reviewer_all').then(res => {
                console.log(res);
                if (res.data.length > 0) {
                    this.tableData = res.data
                    this.isSending2 = false
                } else {
                    alert('没有您需要评审的项目')
                    this.isSending2 = false
                }
            }).console(error => {
                console.log(error)
            })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        add() {
            this.dialogVisible = true
            this.title = '创建项目'
            this.form = {}
        },
        handEdit(index, row) {
            this.dialogVisible = true
            this.title = '编辑记录'
            this.form = JSON.parse(JSON.stringify(row))
        },
        handDelete(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.delete(`/vue/purchase_review/delete?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
        Conditionupdt_y(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.post(`/vue/purchase_review/condition_y?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
        Conditionupdt_n(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.post(`/vue/purchase_review/condition_n?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
        handleClose() {
            this.dialogVisible = false
            this.init()
        },
        reset() {
            let id = undefined
            if ('id' in this.form) {
                id = this.form.id
            }
            this.form = {}
            if (id != undefined) this.form.id = id
        },
        save() {
            this.isSending = true
            console.log(this.form);
            this.$axios.post('/vue/review/review_add', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==' + '请检查内容是否填写完整')
                this.isSending = false
            })
        },
        rightclick() {
            this.visible = true
        },
        jojo(scope) {
            if (this.sxsx == '1') {
                this.$router.push({
                    path: '/dashboard/review/review_table',
                    query: { reviewname: scope.项目名称 }
                });
            } else if (this.sxsx == '2') {
                this.$router.push({
                    path: '/dashboard/review/reviewer_table',
                    query: { reviewname: scope.项目名称 }
                });
            }
        },
    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(250, 250, 250);
}

.review {
    /* border: 1px solid red; */
    height: 88.9%;
}

.review_div1 {
    width: 13%;
    height: 94%;
    margin: 20px;
    float: left;

}

.review_table1 {
    width: 100%;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 10px;

}

.review_table2 {
    width: 1500px;
    height: 92%;
    margin-top: 47px;

}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style>