<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">项目维护管理</el-menu-item>

            </el-menu>
        </div>
        <div class="select_menu">
            <!-- <el-input v-model="input" placeholder="搜索标题和id" style="width: 10%;"></el-input> -->
            <el-input v-model="xmmc" placeholder="请输入项目名称" style="width: 200px; margin-left: 10px;"></el-input>
            <el-button style="margin-left: 10px;" @click="init" :loading="isSending">获取我负责的项目</el-button>
            <el-button style="margin-left: 10px;" @click="initall" :loading="isSending2">获取我的执行项目</el-button>
        </div>
        <div class="auto_project">
            <div class="auto_project_div1">
                <!-- <div style="width: 100%;"><span>未完成</span></div> -->
                <el-button size="medium" @click="add" style="width: 100%;">新增项目</el-button>
                <div class="auto_project_table1">
                    <el-table :data="tableData" style="width: 100%" :show-header="false" height="100%"
                        @row-contextmenu="rightclick" size>
                        <el-table-column width="15">
                            
                        </el-table-column>
                        <el-table-column prop="项目名称" label="标题">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" @click="jojo(scope.row.项目名称)"
                                @contextmenu.prevent="delete_project(scope.row.项目名称)">
                                    {{ scope.row.项目名称 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="功能" width="146">
                            <template slot-scope="scope">
                                <el-button size="medium" plain @click="add_action(scope.row)">添加维护动作</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="auto_project_div1">
                <div class="auto_project_table2">
                    <el-table :data="tableData1" style="width: 100%" height="100%" @row-contextmenu="rightclick">
                        <el-table-column prop="项目名称" label="项目名称" width="105">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    @click="pro_action_er(scope.row.项目名称, scope.row.执行人, scope.row.维护动作)">
                                    {{ scope.row.项目名称 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="executor" label="执行人" width="80"
                            :filters="[{ text: '李健东', value: '李健东' }, { text: '温智超', value: '温智超' }, { text: '陈志芳', value: '陈志芳' }, { text: '李兆轩', value: '李兆轩' }, { text: '张梅丽', value: '张梅丽' }, { text: '罗航', value: '罗航' }, { text: '李秀娟', value: '李秀娟' }, { text: '张莉敏', value: '张莉敏' }, { text: '吴尚熹', value: '吴尚熹' }, { text: '卢凤颖', value: '卢凤颖' }, { text: '林宝玲', value: '林宝玲' }, { text: '周才德', value: '周才德' }, { text: '郭诗艺', value: '郭诗艺' }]"
                            :filter-method="filterTag">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    @click="pro_action_er(scope.row.项目名称, scope.row.执行人, scope.row.维护动作)">
                                    {{ scope.row.执行人 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="维护动作" label="维护动作" width="90">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    @click="pro_action_er(scope.row.项目名称, scope.row.执行人, scope.row.维护动作)">
                                    {{ scope.row.维护动作 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="执行频率" label="频率" width="50">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    @click="pro_action_er(scope.row.项目名称, scope.row.执行人, scope.row.维护动作)">
                                    {{ scope.row.执行频率 }}天
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="固定执行时间" label="固定执行时间" width="105">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper"
                                    @click="pro_action_er(scope.row.项目名称, scope.row.执行人, scope.row.维护动作)">
                                    {{ scope.row.固定执行时间 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <el-popconfirm title="确认删除吗？"
                                    @confirm="delete_actionete(scope.row.项目名称, scope.row.执行人, scope.row.维护动作)">
                                    <el-button size="small" plain type="danger" slot="reference"
                                        style="float: right;margin-left: 3px;">删除</el-button>
                                </el-popconfirm>
                                <el-button size="small" plain type="primary" slot="reference" style="float: right;"
                                    @click="update(scope.row)">编辑</el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>

            <div class="auto_project_div3">
                <el-date-picker v-model="selectedDate" type="date" placeholder="选择日期" @change="handleDateChange($event)"
                    style="float: right;"></el-date-picker>
                <div class="auto_project_table3">
                    <el-table :data="tableData2.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
                        style="width: 100%" height="100%" @row-contextmenu="rightclick" ref="myTable">
                        <el-table-column prop="项目名称" label="项目名称" width="130">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" :class="getStatusColorClass(scope.row)">
                                    {{ scope.row.项目名称 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="项目内容" label="项目内容" width="150">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" :class="getStatusColorClass(scope.row)">
                                    {{ scope.row.项目内容 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="executor" label="执行人" width="80"
                            :filters="[{ text: '李健东', value: '李健东' }, { text: '温智超', value: '温智超' }, { text: '陈志芳', value: '陈志芳' }, { text: '李兆轩', value: '李兆轩' }, { text: '张梅丽', value: '张梅丽' }, { text: '罗航', value: '罗航' }, { text: '李秀娟', value: '李秀娟' }, { text: '张莉敏', value: '张莉敏' }, { text: '吴尚熹', value: '吴尚熹' }, { text: '卢凤颖', value: '卢凤颖' }, { text: '林宝玲', value: '林宝玲' }, { text: '周才德', value: '周才德' }, { text: '郭诗艺', value: '郭诗艺' }]"
                            :filter-method="filterTag">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" :class="getStatusColorClass(scope.row)">
                                    {{ scope.row.执行人 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="维护动作" label="维护动作" width="110">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" :class="getStatusColorClass(scope.row)">
                                    {{ scope.row.维护动作 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="执行状态" label="执行状态" width="95">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" :class="getStatusColorClass(scope.row)">
                                    {{ scope.row.执行状态 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="date" label="执行当天时间" width="105">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" :class="getStatusColorClass(scope.row)">
                                    {{ scope.row.执行时间 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <!-- <el-button size="small" :loading="isSending4" plain
                                    @click="update_actioner(scope.row.项目名称, scope.row.执行人, scope.row.维护动作, scope.row.执行时间, scope.row.是否固定)"
                                    :disabled="checkStatus(scope.row)">点击完成</el-button> -->
                                <el-button size="small" :loading="isSending4" plain @click="handleButtonClick(scope.row)"
                                    v-if="scope.row.执行状态 === '未完成' || scope.row.执行状态 === '超期未完成'">点击完成</el-button>
                                <el-button type="info" size="small" disabled plain
                                    v-else-if="scope.row.执行状态 === '更新数据中'">更新中</el-button>
                                <el-button type="danger" size="small" :loading="isSending4" plain
                                    @click="handleButtonClick(scope.row)" v-else>点击撤销</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[30, 300, 500, 1000, 2000]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="tableData2.length">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="title" :visible="dialogVisible" width="30%" height="50%" :before-close="handleClose">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" placeholder="不能带有特殊字符和空格" />
                </el-form-item>
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" />
                </el-form-item>
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目负责人" placeholder="请选择项目负责人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="save" :loading="isSending1">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog :title="title" :visible="dialogVisible1" width="30%" :before-close="handleClose1">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" placeholder="不能带有特殊字符和空格" />
                </el-form-item>
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" :disabled="true" autocomplete="off" />
                </el-form-item>
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off"
                        placeholder="若没有备注请写上‘无备注’" />
                </el-form-item>
                <el-form-item label="执行人" prop="执行人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.执行人" placeholder="请选择执行人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维护动作" prop="维护动作">
                    <el-select v-model="form.维护动作" filterable allow-create default-first-option placeholder="请选择维护动作">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行频率" prop="执行频率">
                    <el-input-number size="small" v-model="num1"></el-input-number>
                </el-form-item>
                <el-form-item label="是否休息日执行" prop="是否休息日执行" label-width="22%">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.是否周末执行" placeholder="请选择是或否">
                        <el-option v-for="item in tip" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="固定执行时间" prop="固定执行时间">
                    <el-date-picker v-model="form.固定执行时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="save_action" :loading="isSending2">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog :title="title" :visible="dialogVisible2" width="30%" :before-close="handleClose2">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 60%;"
                        placeholder="不能带有特殊字符和空格" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" :disabled="true" style="width: 148%;"
                        autocomplete="off" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="small" plain type="primary" slot="reference" style="float: right;"
                                        @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="primary" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" style="width: 60%;" autocomplete="off" />
                </el-form-item>
                <el-form-item label="执行人" prop="执行人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.执行人" placeholder="请选择执行人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维护动作" prop="维护动作">
                    <el-select v-model="form.维护动作" filterable allow-create default-first-option placeholder="请选择维护动作">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行频率" prop="执行频率">
                    <el-input-number size="small" v-model="num1"></el-input-number>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="是否休息日执行" prop="是否休息日执行">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.是否周末执行" placeholder="请选择是或否" style="width: 60%;">
                        <el-option v-for="item in tip" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-upload multiple style="width: 195px;" class="upload-demo" ref="upload" :file-list="fileList"
                        :on-preview="handlePreview" action :http-request="httpRequest" :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success" :loading="isSending_register"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="update_action" :loading="isSending2">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            input: '',
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            isSending: false,
            isSending1: false,
            isSending2: false,
            xmmc:'',
            isSending4: false,
            isSending_register: false,
            dialogVisible: false,
            dialogVisible1: false,
            dialogVisible2: false,
            projectname: '',
            isButtonDisabled: false,
            fileData: [],
            form: {},
            size: '',
            title: '',
            visible: false,
            pageSize: 30,
            currentPage: 1,
            tableData: [],
            tableData1: [],
            // tableData1: [{ '项目名称': '三星S24 Ultra透明壳-美国', '项目内容': '三星S24 Ultra透明壳', '项目备注': '1', '执行人': '温智超', '维护动作': '备货发货', '是否周末执行': '否', '执行频率': 7 }],
            tableData2: [],
            tableDataover: [],
            selectedDate: '',
            num1: 1,
            rules: {
                项目名称: [{ required: true, message: '输入项目名称以新建任务', trigger: 'blur' }],
                项目内容: [{ required: true, message: '添加项目内容', trigger: 'blur' }],
                项目备注: [{ required: true, message: '添加项目备注', trigger: 'blur' }],
                执行人: [{ required: true, message: '请选择执行人', trigger: 'blur' }],
                维护动作: [{ required: true, message: '请选择维护动作', trigger: 'blur' }],
                项目负责人: [{ required: true, message: '请选择项目负责人', trigger: 'blur' }],
            },
            tip: [{
                value: '是',
                label: '是'
            }, {
                value: '否',
                label: '否'
            }],
            options: [{
                value: '李健东',
                label: '李健东'
            }, {
                value: '温智超',
                label: '温智超'
            }, {
                value: '陈志芳',
                label: '陈志芳'
            }, {
                value: '李兆轩',
                label: '李兆轩'
            }, {
                value: '张梅丽',
                label: '张梅丽'
            }, {
                value: '罗航',
                label: '罗航'
            }, {
                value: '李秀娟',
                label: '李秀娟'
            }, {
                value: '张莉敏',
                label: '张莉敏'
            }, {
                value: '吴尚熹',
                label: '吴尚熹'
            }, {
                value: '卢凤颖',
                label: '卢凤颖'
            }, {
                value: '林宝玲',
                label: '林宝玲'
            }, {
                value: '周才德',
                label: '周才德'
            }, {
                value: '郭诗艺',
                label: '郭诗艺'
            }],
            options1: [{
                value: '前台是否异常',
                label: '前台是否异常'
            }, {
                value: '备货发货',
                label: '备货发货'
            }, {
                value: '销量是否异常',
                label: '销量是否异常'
            }, {
                value: '移入移出',
                label: '移入移出'
            }],
            value: '',
            value1: [],
            value2: [],
            value3: [],
            formLabelWidth: '80px',
            timer: null,
            sxsx: '',
            fand: 0,
            store: [],
            flagnum: 0,
        }
    },
    methods: {
        // @size-change页码展示数量点击事件
        handleSizeChange(val) {
            // 更新每页展示数据size
            this.pageSize = val
        },
        // @current-change页码点击事件
        handleCurrentChange(val) {
            // 更新当前页数是第几页
            this.currentPage = val
        },
        handleButtonClick(row) {
            if (row.执行状态 === '未完成' || row.执行状态 === '超期未完成') {
                this.update_actioner(row.项目名称, row.执行人, row.维护动作, row.执行时间, row.是否固定, row.撤销判断)
            } else {
                this.return_actioner(row.项目名称, row.执行人, row.维护动作, row.执行时间)
            }
        },
        handleDateChange(date) {
            this.selectedDate = date; // 更新选择的日期
            this.filterTableData(); // 调用方法筛选表格数据
        },
        filterTableData() {
            // 根据选择的日期筛选表格数据
            const filteredData = this.tableData2.filter(row => {
                const rowDate = new Date(row.执行时间); // 假设每行数据的日期字段为 date
                const selectedDate = new Date(this.selectedDate); // 将选择的日期转换为 Date 对象
                const rowMonth = rowDate.getMonth(); // 获取每行数据的月份
                const selectedMonth = selectedDate.getMonth()
                const rowYear = rowDate.getYear(); // 获取每行数据的年份份
                const selectedYear = selectedDate.getYear()
                return rowDate.getDate() === this.selectedDate.getDate() && rowMonth === selectedMonth && rowYear === selectedYear // 仅显示当天日期的数据
            });
            // 将筛选后的数据赋值给表格的 data 属性，以更新表格内容
            this.$refs.myTable.data = filteredData;
        },
        // checkStatus(row) {
        //     // 根据执行状态判断是否禁用按钮
        //     if (row.执行状态 === '已完成') {
        //         return true; // 禁用按钮
        //     } else {
        //         return false; // 启用按钮
        //     }
        // },
        getStatusColorClass(row) {
            if (row.执行状态 === '已完成') {
                return 'green-color'; // 自定义绿色类名
            } else if (row.执行状态 === '超期未完成') {
                return 'yellow-color'; // 自定义黄色类名
            }
            return ''; // 其他情况使用默认样式或根据需要添加其他类名
        },
        filterTag(value, row) {
            return row.执行人 === value;
        },
        init() {
            this.isSending = true
            let params = {
				query: this.xmmc
			}
            this.$axios.get('/vue/auto_project/principal_data', { params}).then(res => {
                console.log(res);
                if (res.data) {
                    this.tableData = res.data
                    this.isSending = false
                } else {
                    alert('没有你负责的项目')
                    this.isSending = false
                }
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        initall() {
            this.flagnum = 1
            this.isSending2 = true
            this.$axios.get('/vue/auto_project/select_all_action').then(res => {
                console.log(res);
                if (res.data) {
                    this.tableData1 = res.data
                    this.initall_actoin()
                } else {
                    alert('没有你执行的项目')
                    this.isSending2 = false
                    this.isSending4 = false

                }
            })
                .console(error => {
                    console.log(error)
                    this.isSending2 = false
                    this.isSending4 = false
                })
        },
        initall_actoin() {
            this.isSending2 = true
            this.isSending4 = true
            this.$axios.get('/vue/auto_project/select_all_action_now').then(resd => {
                console.log(resd);
                this.tableData2 = resd.data
                this.isSending2 = false
                this.isSending4 = false
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        delete_project(male_parent) {
			this.$confirm('此操作将该项目删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({
					type: 'success',
					message: '删除成功!'
				})
				this.$axios.post('/vue/auto_project/delete_project', { male_parent: male_parent }).then(res => {
					console.log(res)
					this.init()
				})
					.console(error => {
						console.log(error)
						alert('发生错误:==' + error)
					})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消操作'
				})
			})
		},
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        add() {
            this.dialogVisible = true
            this.title = '创建项目'
            this.form = {}
        },
        update(row) {
            this.title = '编辑维护动作'
            this.form = {}
            this.form = row
            this.form.执行人1 = row.执行人
            this.form.维护动作1 = row.维护动作
            this.dialogVisible2 = true
            this.auto_project_filedata(this.form.项目名称, this.form.执行人, this.form.维护动作)
        },
        add_action(row) {
            this.title = '添加维护动作'
            this.form = {}
            this.form.项目名称 = row.项目名称
            this.form.项目内容 = row.项目内容
            this.dialogVisible1 = true
        },
        handEdit(index, row) {
            this.dialogVisible = true
            this.title = '编辑记录'
            this.form = JSON.parse(JSON.stringify(row))
        },
        handleClose() {
            this.dialogVisible = false
            this.init()
        },
        handleClose1() {
            this.dialogVisible1 = false
            this.fand = 0
            this.jojo(this.form.项目名称)
        },
        handleClose2() {
            this.dialogVisible2 = false
            this.fand = 1
            this.jojo(this.form.项目名称)
        },
        reset() {
            let id = undefined
            if ('id' in this.form) {
                id = this.form.id
            }
            this.form = {}
            if (id != undefined) this.form.id = id
        },
        save() {
            this.isSending1 = true
            console.log(this.form);
            this.$axios.post('/vue/auto_project/principal_add', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending1 = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose() }
                    });
                } else {
                    this.isSending1 = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log(error)
                alert('发生错误:==' + '请检查内容是否填写完整')
                this.isSending1 = false
            })
        }
        ,
        rightclick() {
            this.visible = true
        },
        jojo(project) {
            this.$axios.post('/vue/auto_project/action_day', { name: project }).then(res => {
                console.log(res);
                this.tableData1 = res.data
                if (this.flagnum == 2) {
                    if (this.fand == 1) {
                        this.pro_action_er(this.form.项目名称, this.form.执行人, this.form.维护动作)
                    }
                } else {
                    if (this.fand == 1) {
                        this.initall_actoin()
                    }
                }

            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        pro_action_er(pname, er, action) {
            this.flagnum = 2
            this.$axios.post('/vue/auto_project/actioner_select', { name: pname, er: er, action: action }).then(res => {
                console.log(res);
                this.tableData2 = res.data
                this.isSending4 = false
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        jojo1(project, er, action) {
            this.$axios.post('/vue/auto_project/action_day', { name: project }).then(res => {
                console.log(res);
                this.tableData1 = res.data
                this.pro_action_er(project, er, action)
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        save_action() {
            this.isSending2 = true
            this.form.执行频率 = this.num1
            console.log(this.form);
            this.$axios.post('/vue/auto_project/action_add', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                    this.isSending2 = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending2 = false
                }
            })
                .catch(error => {
                    console.log(error)
                    alert('发生错误:==' + '请检查内容是否填写完整')
                    this.isSending2 = false
                })
        },
        delete_actionete(name, er, action) {
            this.$axios.post('/vue/auto_project/delete_action', { name: name, er: er, action: action }).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.jojo1(name, er, action)
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
        update_actioner(pname, er, action, ztime, guding, pd) {
            this.isSending4 = true
            this.$axios.post('/vue/auto_project/update_actioner', { name: pname, er: er, action: action, ztime: ztime, guding: guding, pd: pd }).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                } else {
                    alert('还未到执行时间')
                }
                // this.pro_action_er(pname, er, action)
                if (this.flagnum == 1) {
                    this.initall()
                } else if (this.flagnum == 2) {
                    this.pro_action_er(pname, er, action)
                }
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        return_actioner(pname, er, action, ztime) {
            this.isSending4 = true
            this.$axios.post('/vue/auto_project/return_actioner', { name: pname, er: er, action: action, ztime: ztime }).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                } else {
                    alert('错误')
                }
                // this.pro_action_er(pname, er, action)
                if (this.flagnum == 1) {
                    this.initall()
                } else if (this.flagnum == 2) {
                    this.pro_action_er(pname, er, action)
                }
            })
                .console(error => {
                    console.log(error)
                    alert('发生错误:==' + error)
                })
        },
        update_action() {
            this.form.执行频率 = this.num1
            console.log(this.form);
            this.$axios.post('/vue/auto_project/update_action', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose2() }
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }

            })
                .catch(error => {
                    console.log(error)
                    alert('发生错误:==' + '请检查内容是否填写完整')
                })
        },
        register(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    console.log("有效")
                    this.$refs.upload.submit();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        httpRequest(param) {
            this.isSending_register = true
            let fileObj = param.file; // 相当于input里取得的files
            let data = new FormData(); // FormData 对象
            data.append("file", fileObj); // 文件对象
            data.append("action", this.form.维护动作);
            data.append("name", this.form.项目名称);
            data.append("er", this.form.执行人);
            this.$axios.post('/vue/auto_project/auto_project_upload', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                if (res.code == 200) {
                    console.log("结果：", res);
                    alert('上传成功')
                } else {
                    alert('上传失败')
                }
                this.isSending_register = false
                this.auto_project_filedata(this.form.项目名称, this.form.执行人, this.form.维护动作)
            }).console(error => {
                console.log("错误：", error);
                alert('发生错误:==' + error)
            });
        },
        download(row) {
            let url = "https://kebeidianzi.com/vue/auto_project/auto_project_download/" + encodeURIComponent(row.文件名) + "?name=" + encodeURIComponent(row.项目名称) + "&action=" + row.维护动作 + "&er=" + row.执行人
            this.$axios.get(`/vue/auto_project/auto_project_download/` + row.文件名, { params: { name: row.项目名称, action: row.维护动作, er: row.执行人 }, responseType: 'blob' }).catch(error => {
                console.log("错误", error)
                alert('下载发生错误:==' + error)
            })
            window.open(url, '_blank')
        },
        auto_project_filedata(name, er, action) {
            this.$axios.post(`/vue/auto_project/auto_project_filedata`, { name: name, er: er, action: action }).then(res => {
                console.log(res)
                this.fileData = res.data
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(250, 250, 250);
}

.auto_project {
    /* border: 1px solid red; */
    height: 88.9%;
}

.auto_project_div1 {
    width: 20.2%;
    height: 94%;
    margin: 20px;
    float: left;


}

.auto_project_div3 {
    width: 23%;
    height: 94%;
    margin: 20px;
    float: left;

}

.auto_project_table1 {
    width: 100%;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 10px;


}

.auto_project_table2 {
    width: 158%;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 47px;

}

.auto_project_table3 {
    width: 800px;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 47px;
    margin-left: 48%;

}

.green-color {
    color: green;
}

.yellow-color {
    color: rgb(192, 192, 84);
}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style>